import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { languageIsos } from "../constants/Languages";
import { parseLanguageIso } from "../utils/utils";
import moment from "moment";

const LangContainer = styled(Grid)`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const LanguageButton = styled("div")`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  :hover {
    cursor: pointer;
  }
`;

const LanguageTitle = styled(Typography)`
  padding: 0 10px;
`;

function PlatformLanguagePicker() {
  const [t, i18n] = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const languages = [
    {
      label: t("languages.da"),
      id: languageIsos.da,
    },
    {
      label: t("languages.en"),
      id: languageIsos.en,
    },
    {
      label: t("languages.nb"),
      id: languageIsos.nb,
    },
  ];

  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  function handleSetLanguage(language: string) {
    i18n.changeLanguage(language);
    handleCloseMenu();
  }
  function handleOpenMenu(event: React.MouseEvent<HTMLDivElement>) {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }
  function handleCloseMenu() {
    setOpen(false);
  }

  return (
    <LangContainer>
      <LanguageButton onClick={(event) => handleOpenMenu(event)}>
        <LanguageTitle variant="body1">
          {t(`languages.${parseLanguageIso(i18n.language)}`)}
        </LanguageTitle>
        <ExpandMore />
      </LanguageButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {languages.map((language) => {
                    if (["nno", "eng", "sv"].includes(language.id)) {
                      return null;
                    }
                    return (
                      <MenuItem
                        key={language.id}
                        onClick={() => handleSetLanguage(language.id)}
                        selected={language.id === i18n.language}
                      >
                        {language.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </LangContainer>
  );
}

export default PlatformLanguagePicker;
