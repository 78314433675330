import { useTranslation } from "react-i18next";

export const routes = {
  _404: "*",
  forgot_password: "/forgot_password",
  home: "/home",
  login: "/login",
  profile: "/profile",
  root: "/",
  theme: "/theme",
  general: {
    root: "/general",
    configuration: {
      branch_configuration: "/general/configuration/branch_configuration",
      branch_configuration_chosen_branch:
        "/general/configuration/branch_configuration/:branch",
      customer_data: "/general/configuration/customer_data",
      external_systems: "/general/configuration/external_systems",
      identity: "/general/configuration/identity",
      library_system: "/general/configuration/library_system",
      opening_hours: "/general/configuration/opening_hours",
      test_information: "/general/configuration/test_information",
    },
    users: {
      user_administration: "/general/users/user_administration",
    },
    customer_settings: {
      customer_groups: "/general/customer_settings/customer_groups",
    },
    content: {
      root: "/general/content",
      announcements: "/general/content/announcements",
      announcement_configuration:
        "/general/content/announcements/:announcement",
      surveys: "/general/content/surveys",
      survey_configuration: "/general/content/surveys/:survey",
    },
  },
  library_app: {
    root: "/library_app",
    configuration: {
      checkins_and_checkouts:
        "/library_app/configuration/checkins_and_checkouts",
      checkins_and_checkouts_branch:
        "/library_app/configuration/checkins_and_checkouts/:branch",
      ip_addresses: "/library_app/configuration/ip_addresses",
      news_and_events: "/library_app/configuration/news_and_events",
    },
    operation: {
      statistics: "/library_app/operation/statistics",
    },
  },
  access: {
    root: "/access",
    configuration: {
      audio_control: "/access/configuration/audio_control",
      block_list: "/access/configuration/block_list",
      branch_configuration: "/access/configuration/branch_configuration",
      branch_configuration_chosen_branch:
        "/access/configuration/branch_configuration/:branch",
      chosen_validation_ruleset: "/access/configuration/common_config/:ruleset",
      common_config: "/access/configuration/common_config",
      event_states: "/access/configuration/event_states",
      scheduling: "/access/configuration/scheduling",
      translations: "/access/configuration/translations",
      units: "/access/configuration/units",
    },
    operation: {
      root: "/access/operation",
      log: "/access/operation/log",
      unit_status: "/access/operation/unit_status",
    },
  },
  assist: {
    root: "/assist",
    configuration: {
      units: "/assist/configuration/units",
      common_config: "/assist/configuration/common_config",
    },
  },
  butler: {
    root: "/butler",
    configuration: {
      branch_configuration: "/butler/configuration/branch_configuration",
      branch_configuration_chosen_branch:
        "/butler/configuration/branch_configuration/:branch",
      common_config: "/butler/configuration/common_config",
      translations: "/butler/configuration/translations",
      units: "/butler/configuration/units",
    },
    operation: {
      root: "/butler/operation",
      statistics: "/butler/operation/statistics",
      unit_status: "/butler/operation/unit_status",
      meta_unit_status: "/butler/operation/meta_unit_status",
    },
  },
  people_counter: {
    root: "/people_counter",
    configuration: {
      units: "/people_counter/configuration/units",
    },
    operation: {
      root: "/people_counter/operation",
      statistics: "/people_counter/operation/statistics",
    },
  },
  gates: {
    root: "/gates",
    configuration: {
      units: "/gates/configuration/units",
    },
    operation: {
      root: "/gates/operation",
      log: "/gates/operation/log",
    },
  },
  inspire: {
    root: "/inspire",
    configuration: {
      units: "/inspire/configuration/units",
      common_config: "/inspire/configuration/common_config",
      translations: "/inspire/configuration/translations",
    },
  },
  poster: {
    root: "/poster",
    configuration: {},
  },
  customers: {
    root: "/customers",
    configuration: {
      select: "/customers/select",
    },
  },
};

export const RoutesTranslations = () => {
  const [t] = useTranslation();
  return {
    access: t("access.title"),
    announcements: t("content.announcements.title"),
    assist: t("assist.title"),
    audio_control: t("access.audio_control.title"),
    block_list: t("access.block_list.title"),
    branch_configuration: t("general.branch_configuration.title"),
    butler: t("butler.title"),
    checkins_and_checkouts: t("library_app.checkins_and_checkouts.title"),
    common_config: t("butler.common_config.title"),
    content: t("content.title"),
    customer_data: t("customers.data"),
    customer_groups: t("customer_settings.customer_groups.title"),
    customer_settings: t("customer_settings.title"),
    event_states: t("access.event_states.title"),
    external_systems: t("general.external_systems.title"),
    forgot_password: t("forgot_password.title"),
    gates: t("gates.title"),
    general: t("general.title"),
    home: t("home.root"),
    identity: t("general.identity.title"),
    inspire: t("inspire.title"),
    ip_addresses: t("library_app.ip_addresses.title"),
    library_app: t("library_app.title"),
    library_services: t("library_app.library_services.title"),
    library_system: t("general.library_system.title"),
    log: t("log.title"),
    news_and_events: t("library_app.news_and_events.title"),
    people_counter: t("people_counter.title"),
    profile: t("profile.title"),
    scheduling: t("access.scheduling.title"),
    search: t("general.search.title"),
    statistics: t("operation.butler.statistics"),
    surveys: t("content.surveys.title"),
    test_information: t("general.test_information.title"),
    theme: t("theme"),
    translations: t("butler.translations.title"),
    units: t("butler.units.title"),
    unit_status: t("operation.butler.unit_status"),
    meta_unit_status: t("operation.butler.meta_unit_status"),
    user_administration: t("users.user_administration"),
    users: t("customers.users"),
  };
};
