import { includes, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Navigate } from "react-router";
import { RouteProps } from "react-router-dom";
import { routes } from "../Routes";
import { useAuth0 } from "../auth0/ReactAuth0Wrapper";
import { stateCategoryMap } from "../constants/state";
import { useActivatedServices } from "../hooks/hooks";
import { useGlobalState } from "../state";
import { RoleLevel } from "../types/authentication";
import { ServiceTypeLowerCase } from "../types/services";
import { hasRole, isPermitted } from "../utils/authorization";

type ProtectedRouteProps = RouteProps & {
  element: React.ReactElement;
  onNotAuthorizedRedirectTo?: string;
  path: string;
  requiredRole?: string;
  requiredRoleLevel?: RoleLevel;
  requiredService?: ServiceTypeLowerCase;
};

function ProtectedRoute(props: ProtectedRouteProps): React.ReactElement {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const [roleLevels] = useGlobalState(stateCategoryMap.ROLE_LEVELS);
  const { activatedServices, completed } = useActivatedServices();
  const {
    element,
    onNotAuthorizedRedirectTo,
    path,
    requiredRole,
    requiredRoleLevel,
    requiredService,
  } = props;

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path },
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  if (
    !!requiredService &&
    completed &&
    !includes(activatedServices, requiredService)
  ) {
    return <Navigate to={routes.home} replace />;
  }

  if (
    (!isEmpty(roleLevels) &&
      requiredRoleLevel &&
      !isPermitted(user, roleLevels, requiredRoleLevel)) ||
    (requiredRole && !hasRole(user, [requiredRole]))
  ) {
    return <Navigate to={onNotAuthorizedRedirectTo || routes.home} replace />;
  }

  const render = isAuthenticated === true ? element : <></>;
  return render;
}

export default ProtectedRoute;
