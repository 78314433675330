import { Auth0UserProfile } from "auth0-js";
import { createGlobalState } from "react-hooks-global-state";
import { customerNotloaded } from "../constants/constants";
import { ConfigPermissionMap, RoleLevels } from "../types/authentication";
import { ConfigMap } from "../types/configs";
import {
  GlobalError,
  GlobalSnackbar,
  IdLabelPair,
  LanguageMap,
} from "../types/interfaces";
import { ProductStatus } from "../types/products";
import { ID } from "../types/types";

interface GlobalStateProps {
  authToken: string;
  chosenUnitName: string;
  configPermissionMap: ConfigPermissionMap;
  countries: IdLabelPair[];
  customer: CustomerState;
  customerUsers: Auth0UserProfile[];
  keyboardTypes: IdLabelPair[];
  languages: LanguageMap[];
  roleLevels: RoleLevels;
  user: any;
  globalSnackbar: GlobalSnackbar;
  globalLoading: boolean;
  globalError: GlobalError;
}

export interface CustomerState {
  configMap?: ConfigMap;
  country?: string;
  customerError: boolean;
  id: string;
  loading: boolean;
  name: string;
  notUpdated?: boolean;
  products: ProductStatus[];
  update: boolean;
  partnerId: ID;
}

export const { useGlobalState } = createGlobalState<GlobalStateProps>({
  customer: {
    id: customerNotloaded,
    name: "",
    customerError: false,
    loading: false,
    update: false,
    products: [],
    partnerId: "",
  },
  roleLevels: {},
  configPermissionMap: {},
  authToken: "",
  user: undefined,
  globalSnackbar: { message: "", variant: "simple", secondaryButtonText: "" },
  globalLoading: false,
  globalError: { isFeedback: false, message: null },
  chosenUnitName: "",
  countries: [
    {
      label: "Danmark",
      id: "DNK",
    },
    {
      label: "Sverige",
      id: "SWE",
    },
    {
      label: "Norge",
      id: "NOR",
    },
  ],
  languages: [
    {
      label: "Dansk",
      id: "dan",
    },
    {
      label: "Deutsch",
      id: "deu",
    },
    {
      label: "English",
      id: "eng",
    },
    {
      label: "Faroese",
      id: "fao",
    },
    {
      label: "Norsk (nynorsk)",
      id: "nno",
    },
    {
      label: "Norsk (bokmål)",
      id: "nob",
    },
    {
      label: "Svensk",
      id: "swe",
    },
  ],
  keyboardTypes: [
    {
      label: "Numerisk",
      id: "numeric",
    },
    {
      label: "Numerisk med N tast",
      id: "numericwithnkey",
    },
    {
      label: "Alfabetisk",
      id: "alphabetical",
    },
  ],
  customerUsers: [
    {
      name: "Name",
      user_metadata: { roles: ["admin"] },
      user_id: "19320493",
      email: "aa@aa.aa",
      nickname: "nickname",
      picture: "nun",
      clientID: "dummycid",
      identities: [],
      created_at: "TS-666",
      updated_at: "TS-999",
      sub: "sub",
    },
  ],
});