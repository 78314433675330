import { useMutation, useQuery } from "@apollo/client";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { lowerCase, orderBy, sortBy } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setConfigValuesMutation } from "../../../GraphQL/config/mutations";
import {
  createCustomerMutation,
  customerProductMutation,
} from "../../../GraphQL/customer/mutations";
import { getCustomerGroupsQuery } from "../../../GraphQL/customer/queries";
import { CancelButton, ConfirmButton } from "../../../components/Buttons";
import { RASelect } from "../../../components/Inputs/RASelect";
import { RATextField } from "../../../components/Inputs/RATextField";
import { productKeysMap } from "../../../constants/Products";
import {
  PARTNERADMIN,
  SYSTEMEMPLOYEE,
} from "../../../constants/authentication";
import { valueFromMap } from "../../../constants/configs";
import { configKeys } from "../../../constants/keys";
import { stateCategoryMap } from "../../../constants/state";
import { useIsPermitted, usePartnerId } from "../../../hooks/authorization";
import {
  ButtonGrid,
  ContentStack,
  FormSectionGrid,
  FormSectionStack,
} from "../../../layouts/Body";
import { useGlobalState } from "../../../state";
import { ProductType } from "../../../types/products";
import { ID } from "../../../types/types";
import { consoleError } from "../../../utils/logs";
import { isLetter } from "../../../utils/utils";
import { validateRegExp } from "../../../utils/validations";

interface CountryOption {
  label: string;
  id: ID;
  iso: ID;
}
interface CreateCustomerFormProps {
  onClose: (refetchCustomers: boolean) => void;
}

function CreateCustomerForm(props: CreateCustomerFormProps) {
  const [t] = useTranslation();
  const [_globalError, setGlobalError] = useGlobalState(
    stateCategoryMap.GLOBAL_ERROR
  );
  const [globalLoading, setGlobalLoading] = useGlobalState(
    stateCategoryMap.GLOBAL_LOADING
  );
  const { isPermitted: advEditPermission } = useIsPermitted(SYSTEMEMPLOYEE);
  const { isPermitted: editPermission } = useIsPermitted(PARTNERADMIN);
  const partnerID = usePartnerId();
  const [countries, setCountries] = useState<CountryOption[]>([]);
  const [_snack, setGlobalSnackbar] = useGlobalState(
    stateCategoryMap.GLOBAL_SNACKBAR
  );
  const [newCustomerState, setNewCustomerState] = useState({
    country: "",
    id: "",
    name: "",
    products: [
      { type: productKeysMap.BUTLER, enabled: false },
      { type: productKeysMap.APP, enabled: false },
      { type: productKeysMap.ASSIST, enabled: false },
      { type: productKeysMap.GATES, enabled: false },
      { type: productKeysMap.DOOR_ACCESS, enabled: false },
      { type: productKeysMap.INSPIRE, enabled: false },
      { type: productKeysMap.PEOPLE_COUNTER, enabled: false },
    ],
    partnerId: "",
  });
  const [setConfigValues] = useMutation(setConfigValuesMutation);
  const [createCustomer, { error }] = useMutation(createCustomerMutation);
  const [updateCustomerProduct] = useMutation(customerProductMutation);
  const {
    loading,
    error: groupsError,
    data,
  } = useQuery(getCustomerGroupsQuery);

  useEffect(() => {
    if (!!error) {
      setGlobalError({ message: Error(error.message) });
    }
    if (!!groupsError) {
      setGlobalError({ message: Error(groupsError.message) });
    }
  }, [error, groupsError]);

  useEffect(() => {
    if (!!data) {
      let newCountries: CountryOption[] = [];
      data.getCustomerGroups.forEach((element: any) => {
        const countryCode = element.name.substr(
          element.name.lastIndexOf(".") + 1
        );
        newCountries.push({
          id: element.id,
          iso: countryCode,
          label: t(`countries.${countryCode.toLowerCase()}`),
        });
      });
      setCountries(newCountries);
    }
  }, [data]);

  useEffect(() => {
    if (!!partnerID) {
      setNewCustomerState({
        ...newCustomerState,
        partnerId: partnerID,
      });
    }
  }, [partnerID]);

  if (loading) return <LinearProgress />;
  if (groupsError) {
    consoleError(groupsError);
    return <p>{t("error_fetch_contact_redia")}</p>;
  }

  function handleToggleProduct(checked: boolean, name: ProductType) {
    let products = newCustomerState.products;
    if (
      !newCustomerState.products.find((product: any) => product.type === name)
    ) {
      products.push({ type: name, enabled: checked });
    } else {
      for (let i in products) {
        if (products[i].type === name) {
          products[i].enabled = checked;
          break;
        }
      }
    }
    setNewCustomerState({ ...newCustomerState, products: products });
  }

  async function updateProducts(id: string) {
    for (let i = 0; i < newCustomerState.products.length; i++) {
      const product = {
        ...newCustomerState.products[i],
        customerID: id,
      };
      await updateCustomerProduct({
        variables: { params: product },
      });
    }
  }

  async function handleSetConfigValues(id: string) {
    const index = countries.findIndex(
      (x) => x.iso === newCustomerState.country
    );
    try {
      const res = await setConfigValues({
        variables: {
          customerId: id,
          params: [
            {
              entity: id,
              key: configKeys.customer.customerGroup,
              value: countries[index].id,
              valueFrom: valueFromMap.CUSTOMER,
            },
          ],
        },
      });
      if (res.data.setConfigValues.length > 0) {
        return true;
      }
    } catch (error) {
      setGlobalError({
        message: error,
      });
      return;
    }
  }

  async function handleConfirmChanges() {
    if (!readyForSave()) {
      setGlobalError({
        isFeedback: true,
        message: Error(t("snackbar.fill_all_required")),
      });
      return;
    }
    setGlobalLoading(true);
    try {
      const res = await createCustomer({
        variables: {
          params: {
            name: newCustomerState.name,
            country: newCustomerState.country,
            partnerId: !!newCustomerState.partnerId
              ? newCustomerState.partnerId
              : null,
            id: newCustomerState.id,
          },
        },
      });
      const err = await handleSetConfigValues(res.data.createCustomer.id);
      if (err) {
        console.error("Error in the process of setting config values");
        return;
      }
      if (res.data.createCustomer.id) {
        await updateProducts(res.data.createCustomer.id);
      }
      setGlobalLoading(false);
      setGlobalSnackbar({
        message: `${t("snackbar.customer_created")}: ${
          res.data.createCustomer.id
        }`,
        variant: "success",
      });
      props.onClose(true);
    } catch (error) {
      consoleError(error);
    }
  }

  function handleCancelChanges() {
    props.onClose(false);
  }

  function readyForSave() {
    if (advEditPermission) {
      return (
        newCustomerState.country &&
        customerIdValid() &&
        newCustomerState.id &&
        newCustomerState.name
      );
    } else if (editPermission) {
      return (
        newCustomerState.country &&
        newCustomerState.id &&
        customerIdValid() &&
        newCustomerState.name &&
        newCustomerState.partnerId
      );
    }
    return false;
  }

  function customerIdValid() {
    const { id } = newCustomerState;
    if (!isLetter(id.charAt(0))) return false; // start with lowercase letter
    if (id.length < 5 || id.length > 30) return false; // 5 to 30 chars
    if (id.charAt(0) !== id.charAt(0).toLowerCase()) return false; // first char lowercase
    const valid = validateRegExp(id, /^[a-zA-Z0-9-_]+$/); // allowed chars a-z, A-Z, 0-9, -, _
    return valid;
  }

  return (
    <>
      <ContentStack>
        <FormSectionGrid direction="row">
          <Grid item xs={12}>
            <RATextField
              required
              disabled={!editPermission || globalLoading}
              label={t("customers.customer_name")}
              value={newCustomerState.name}
              onChange={(event) =>
                setNewCustomerState({
                  ...newCustomerState,
                  name: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <RASelect
              required
              disabled={!editPermission || globalLoading}
              id="select_country"
              label={t("customers.country")}
              value={newCustomerState.country}
              onChange={(event) =>
                setNewCustomerState({
                  ...newCustomerState,
                  country: event.target.value,
                })
              }
            >
              {orderBy(countries, "label", "asc").map(
                (option: CountryOption) => (
                  <MenuItem key={option.id} value={option.iso}>
                    {option.label}
                  </MenuItem>
                )
              )}
            </RASelect>
          </Grid>
          <Grid item xs={12}>
            <RATextField
              tooltipText={t("tooltip_texts.customer_id_validation")}
              helperText={t("customers.customer_id_helper")}
              required
              error={
                newCustomerState.id.length > 0 ? !customerIdValid() : false
              }
              disabled={!editPermission || globalLoading}
              label={t("customers.customer_id")}
              value={newCustomerState.id}
              onChange={(event) =>
                setNewCustomerState({
                  ...newCustomerState,
                  id: event.target.value,
                })
              }
            />
          </Grid>
          {advEditPermission && (
            <Grid item xs={12}>
              <RATextField
                disabled={!advEditPermission || loading}
                label={t("customers.partner_id")}
                value={newCustomerState.partnerId}
                helperText={t("customers.partner_id_helpertext")}
                onChange={(event) =>
                  setNewCustomerState({
                    ...newCustomerState,
                    partnerId: event.target.value,
                  })
                }
              />
            </Grid>
          )}
        </FormSectionGrid>
        <FormSectionGrid direction="row" title={t("customers.products")}>
          <Grid item xs={12} lg={6}>
            <FormSectionStack spacing={2}>
              {sortBy(newCustomerState.products, [
                function (p) {
                  return t(`customers.libry_${lowerCase(p.type)}`);
                },
              ]).map((product) => {
                return (
                  <Grid key={product.type}>
                    <FormControlLabel
                      disabled={globalLoading}
                      checked={product ? product.enabled : false}
                      onChange={(_, checked) =>
                        handleToggleProduct(checked, product.type)
                      }
                      value={lowerCase(product.type)}
                      control={<Checkbox />}
                      label={t(`customers.libry_${product.type.toLowerCase()}`)}
                    />
                  </Grid>
                );
              })}
            </FormSectionStack>
          </Grid>
        </FormSectionGrid>
      </ContentStack>
      <ButtonGrid>
        <CancelButton onClick={() => handleCancelChanges()}>
          {t("cancel")}
        </CancelButton>
        <ConfirmButton
          onClick={() => handleConfirmChanges()}
          disabled={!readyForSave()}
        >
          {t("save")}
        </ConfirmButton>
      </ButtonGrid>
    </>
  );
}

export default CreateCustomerForm;
